<template>
  <div class="cookies-accept" v-if = "!cookiesAccepted">
      <div class = "cookies-accept-text"> 
        <template v-if = "!showDetail">
          Для обеспечения оптимальной работы и улучшения пользовательского опыта на сайте используются технологии cookie. Продолжая пользоваться сайтом, вы соглашаетесь с размещением cookie-файлов на вашем устройстве на условиях, изложенных в <a href = "https://lk.taxiaggregator.ru/static/politics.pdf" target="_blank">Политике конфиденциальности</a>.
          <br/>
          <a @click = "showDetail = !showDetail;" >Подробнее</a>
        </template>
        
        <template v-else>
            Для обеспечения оптимальной работы и улучшения пользовательского опыта на сайте могут использоваться системы веб-аналитики (в том числе Яндекс.Метрика), которые могут размещать на вашем устройстве cookie-файлы. Продолжая пользоваться сайтом, вы соглашаетесь с применением указанных технологий, в т.ч. для аналитики с использованием внешних сервисов, и  размещением cookie-файлов. Вы можете удалить cookie-файлы с вашего устройства через настройки браузера, а также заблокировать размещение cookie-файлы, однако при этом некоторые функци сайта могут перестать работать. Дополнительную информацию вы можете найти в <a href = "https://lk.taxiaggregator.ru/static/politics.pdf" target="_blank">Политике конфиденциальности</a>.
        </template>

      </div>
      <div class = "cookies-accept-button" @click = "onCookiesAccept">
          Понятно
      </div>
  </div>
</template>

<script>

import { Component, Prop, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '365d'})

@Component()
export default class CookiesAccept extends Vue {
  cookiesAccepted = false;
  showDetail = false;

  acceptedKeyName = 'CookiesAccepted';


  created() {
    this.cookiesAccepted = this.$cookies.isKey(this.acceptedKeyName) && this.$cookies.get(this.acceptedKeyName)
    if(this.cookiesAccepted) this.$cookies.set(this.acceptedKeyName, true)
  }

  onCookiesAccept(){
    this.$cookies.set(this.acceptedKeyName, true)
    this.cookiesAccepted = this.$cookies.isKey(this.acceptedKeyName) && this.$cookies.get(this.acceptedKeyName)
  }
}
</script>

<style lang="scss" scoped>
  .cookies-accept{
      display: flex;
      align-items: center;

      position: fixed;
      bottom: 0px !important;
      transform: translateY(0%);
      z-index: 99999;
      left: 0;
      background: #C194FF;
      border-radius: 20px;
      padding-top: 10px;
      padding-right: 9px;
      padding-bottom: 10px;
      padding-left: 8px;

      font-family: "Roboto";
      font-style: normal;

      font-size: 12px;
      line-height: 14px;
      text-align: left;

      color:#FFFFFF;


  }
  a{
    text-decoration: underline;
    cursor: pointer;
    color:#FFFFFF;
  }
  .cookies-accept-button{
    cursor: pointer;
    text-align: center;
    padding: 9px 40px;
    border-radius: 6px;
    gap: 10px;
    border-width: 1px;
    border: 1px solid #40256B;
    margin: 10px 0;
  }


</style>
