<template>
  <div
    class="taxi-form"
    :class="mailing ? `taxi-form--${page}` : `information information--${page}`"
  >
    <div class="taxi-form__wrapper">
      <h3 class="taxi-form__title">
        {{ mailing ? formMailings[page].title : formInformation[page].title }}
      </h3>
      <p class="taxi-form__text">
        {{ mailing ? formMailings[page].text : formInformation[page].text }}
      </p>
    </div>
    <FormulateForm
      class="taxi-form__form"
      v-if="mailing"
      @submit="onSubmit"
      autocomplete="off"
    >
      <div>
        <FormulateInput
          :class="`input__email input__email--${page}`"
          v-model="formMailings[page].email"
          name="email"
          type="text"
          validation="^required|email"
          placeholder="Ваша эл. почта"
          :validation-messages="{
            required: 'Это поле обязательно',
            email: 'Введите реальный email',
          }"
          autocomplete="nope"
        />
        <FormulateInput
          :class="`input__checkbox input__checkbox--${page}`"
          v-model="formMailings[page].checkbox"
          name="checkbox"
          type="checkbox"
          :label="labelCheckbox"
          validation="required"
          :validation-messages="{
            required: 'Ваше согласие обязательно',
          }"
        >
          <template #label="{ label, id }">
            <label :for="id" v-html="label" class="formulate-input-label">
            </label>
          </template>
        </FormulateInput>
      </div>
      <button class="taxi-form__btn" v-if="!text">
        <i
          ><svg
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="11" width="4" height="2" fill="white" />
            <rect x="10" y="11" width="4" height="2" fill="white" />
            <rect x="17" y="11" width="4" height="2" fill="white" /></svg></i
        >ПОДПИСАТЬCЯ НА РАССЫЛКУ
      </button>
      <button class="taxi-form__btn" v-else disabled>
        <i
          ><svg
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="11" width="4" height="2" fill="white" />
            <rect x="10" y="11" width="4" height="2" fill="white" />
            <rect x="17" y="11" width="4" height="2" fill="white" /></svg></i
        >{{ text }}
      </button>
    </FormulateForm>
    <FormulateForm
      v-if="!mailing"
      class="taxi-form__form"
      @submit="onSubmit"
      autocomplete="off"
    >
      <div>
        <FormulateInput
          :class="`input__name input__name-information--${page}`"
          v-model="formInformation[page].name"
          name="name"
          type="text"
          label="Как вас зовут?"
          validation="^required|matches:/^[а-яА-Яa-zA-ZёЁ ]+$/"
          :validation-messages="{
            required: 'Это поле обязательно',
            matches: 'Необходимо указать имя верно',
          }"
          autocomplete="off"
        />
        <FormulateInput
          :class="`input__email input__email-information--${page}`"
          v-model="formInformation[page].company"
          name="company"
          type="text"
          placeholder="Название компании"
          validation="^required"
          :validation-messages="{
            required: 'Это поле обязательно',
            matches: 'Необходимо указать имя верно',
          }"
          autocomplete="off"
        />
        <FormulateInput
          :class="`input__email input__email-information--${page}`"
          v-model="formInformation[page].email"
          name="email"
          type="text"
          validation="^required|email"
          placeholder="Ваша эл. почта"
          :validation-messages="{
            required: 'Это поле обязательно',
            email: 'Введите реальный email',
          }"
          autocomplete="nope"
        />
        <FormulateInput
          :class="`input__phone input__phone-information--${page}`"
          v-model="formInformation[page].phone"
          name="phone"
          type="tel"
          v-mask="`8(###)###-##-##`"
          placeholder="Ваш моб. телефон"
          validation="^required|min:11,length"
          :validation-messages="{
            required: 'Это поле обязательно',
            min: 'Введите номер верно',
          }"
          autocomplete="nope"
        />
        <FormulateInput
          :class="`input__checkbox input__checkbox-information--${page}`"
          v-model="formInformation[page].checkbox"
          name="checkbox"
          type="checkbox"
          :label="labelCheckbox"
          validation="required"
          :validation-messages="{
            required: 'Ваше согласие обязательно',
          }"
        >
          <template #label="{ label, id }">
            <label :for="id" v-html="label" class="formulate-input-label">
            </label>
          </template>
        </FormulateInput>
      </div>
      <button v-if="!loading" class="taxi-form__btn">
        <i
          ><svg
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="11" width="4" height="2" fill="white" />
            <rect x="10" y="11" width="4" height="2" fill="white" />
            <rect x="17" y="11" width="4" height="2" fill="white" /></svg></i
        >Отправить
      </button>
      <button v-else class="taxi-form__btn">Отправка...</button>
    </FormulateForm>
    <div v-if="dialog" class="dialog">
      <div class="dialog__window">
        <div class="dialog__wrapper">
          <h2 class="dialog__thx">Спасибо!</h2>
          <p class="dialog__text">Данные успешно отправлены.</p>
        </div>
        <img class="dialog__img" width="212" src="@/assets/img/dialog.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import VueMask from "v-mask";
Vue.use(VueMask);

@Component()
export default class TaxiForm extends Vue {
  @Prop({ type: String, default: "index" }) page;
  @Prop({ type: Boolean, default: true }) mailing;
  @Prop({ type: Boolean, default: true }) driverNew;


  labelCheckbox = `Согласен с <a href="https://lk.taxiaggregator.ru/static/offer.pdf" target="_blank">условиями использования сервиса</a> <br/> и ознакомлен с <a href="https://lk.taxiaggregator.ru/static/politics.pdf" target="_blank">Политикой конфиденциальности</a>`;

  text = "";

  formMailings = {};/* {
    index: {
      title: "Не упустите ничего важного",
      text: "Кратко и по делу без спама и надоедливых писем ",
      email: "",
      checkbox: false,
    },
    driver: {
      title: "Понимать, что происходит",
      text: "Гораздо проще с нашей рассылкой. Только важные новости без спама и надоедливых писем",
      email: "",
      checkbox: false,
    },
    park: {
      title: "Рассылка Таксиагрегатора",
      text: "Чтобы оставаться в курсе самых важных новостей и участвовать в тест-драйвах наших новых сервисов",
      email: "",
      checkbox: false,
    },
    driverNew: {
      title: "Подписаться",
      text: "чтобы оставаться в курсе наших новостей",
      email: "",
      checkbox: false,
    },
  } */;

  formInformation = {
    driver: {
      title: "Хотите узнать больше?",
      text: "Оставьте заявку, мы перезвоним, расскажем все детали и ответим на любые вопросы",
      name: "",
      email: "",
      phone: "",
      checkbox: false,
    },
    park: {
      title: "Нужна помощь?",
      text: "Оставьте заявку – мы перезвоним, расскажем все детали и поможем подобрать оптимальные условия.",
      role: "Таксопарк",
      name: "",
      email: "",
      phone: "",
      company: "",
      checkbox: false,
    },
  };

  loading = false;

  dialog = false;

  created() {
    if (this.driverNew) {
       this.formMailings = {
        index: {
          title: "Не упустите ничего важного",
          text: "Кратко и по делу без спама и надоедливых писем",
          email: "",
          checkbox: false,
        },
        driver: {
          title: "Подписаться",
          text: "чтобы оставаться в курсе наших новостей",
          email: "",
          checkbox: false,
        },
        park: {
          title: "Рассылка Таксиагрегатора",
          text: "Чтобы оставаться в курсе самых важных новостей и участвовать в тест-драйвах наших новых сервисов",
          email: "",
          checkbox: false,
        },
      };
    } else {
      this.formMailings = {
        index: {
          title: "Не упустите ничего важного",
          text: "Кратко и по делу без спама и надоедливых писем ",
          email: "",
          checkbox: false,
        },
        driver: {
          title: "Понимать, что происходит",
          text: "Гораздо проще с нашей рассылкой. Только важные новости без спама и надоедливых писем",
          email: "",
          checkbox: false,
        },
        park: {
          title: "Рассылка Таксиагрегатора",
          text: "Чтобы оставаться в курсе самых важных новостей и участвовать в тест-драйвах наших новых сервисов",
          email: "",
          checkbox: false,
        },
      };
    }
  }

  async onSubmit() {
    if (this.page === "park" && !this.mailing) {
      this.loading = true;
      this.dialog = true;

      let bodyFormData = new FormData();

      const cookiesStr = document.cookie.split("; ");
      const cookies = {};
      cookiesStr.forEach( _s => {
          const _arr = _s.split("=")
          cookies[_arr[0]] = _arr[1];
      });
      if(cookies._ga_cid) bodyFormData.set("_ga_cid", cookies._ga_cid); 

      bodyFormData.set("role", this.formInformation.park.role);
      bodyFormData.set("name", this.formInformation.park.name);
      bodyFormData.set("company", this.formInformation.park.company);
      bodyFormData.set("phone", this.formInformation.park.phone);
      bodyFormData.set("email", this.formInformation.park.email);

      const searchString = new URLSearchParams(window.location.search);
      let params = {};

      for (let item of searchString) {
        params[item[0]] = item[1];
      }

      for (let item in params) {
        let value = searchString.get(item);
        bodyFormData.set(`${item}`, value);
      }

      bodyFormData.set("url", window.location.href);

      let res = await this.axios({
        method: "post",
        url: "https://taxiaggregator.ru:9090/request_handler.php",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (res.status === 200) {
        this.loading = false;
        this.dialog = true;
        setTimeout(() => {
          this.dialog = false;
        }, 2000);
      }
      console.log(res);
      ym(57052429, "reachGoal", params);
      gtag("event", "submit", {
        event_category: "form",
        event_action: "submit",
      });
    }
    if (this.mailing) {
      let bodyFormData = new FormData();

      const cookiesStr = document.cookie.split("; ");
      const cookies = {};
      cookiesStr.forEach( _s => {
          const _arr = _s.split("=")
          cookies[_arr[0]] = _arr[1];
      });
      if(cookies._ga_cid) bodyFormData.set("_ga_cid", cookies._ga_cid); 

      bodyFormData.set("email", this.formMailings[this.page].email);

      let res = await this.axios({
        method: "post",
        url: "https://taxiaggregator.ru:9090/rh2/rh2.php",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });

      if (res.status === 200) {
        if (res.data === "Слишком много запросов с Вашего адреса!") {
          this.text = res.data;
          setTimeout(() => {
            this.text = "";
          }, 7000);
        } else {
          this.text = res.data;
          setTimeout(() => {
            this.text = "";
          }, 7000);
        }
      }
      console.log(res);
      ym(57052429, "reachGoal", "mailingForm");
      gtag("event", "submit", {
        event_category: "form",
        event_action: "submit",
      });
    }
  }
}
</script>

<style lang="scss">
.information {
  .input__email,
  .input__phone {
    margin-top: 17px;
  }
}

.input {
  &__name {
    &[data-is-showing-errors] {
      .formulate-input-wrapper {
        border: 2px solid #ff6691;

        .formulate-input-label {
          color: #ff6691;
          background: #005051;
        }
      }
    }
    .formulate-input-errors {
      font-family: "GTAmericaEx";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #ff6691;
    }
    .formulate-input-wrapper {
      position: relative;
      padding-left: 17px;
      padding-right: 17px;
      background: inherit;
      border: 2px solid #38ce97;
      border-radius: 6px;
      box-sizing: border-box;

      .formulate-input-label {
        position: absolute;
        min-width: 89px;
        font-family: "GT America LCG";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        top: -8px;
        justify-content: center;
        background: #005051;

        color: #38ce97;
      }
    }
    input {
      width: 100%;
      height: 61px;
      border: none;
      outline: none;
      box-sizing: border-box;
      background: inherit;

      font-family: "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #fff;

      @media (max-width: 900px) {
        height: 44px;
      }
    }

    &-information--park {
      &[data-is-showing-errors] {
        .formulate-input-wrapper {
          border: 2px solid #ff6691;

          .formulate-input-label {
            color: #ff6691;
            background: #40256b;
          }
        }
      }

      &:hover {
        .formulate-input-wrapper {
          border: 2px solid #a562ff;
        }
      }
      .formulate-input-wrapper {
        border: 2px solid rgba(165, 98, 255, 0.1);

        .formulate-input-label {
          color: #a562ff;
          background: #40256b;
        }
      }
    }
  }

  &__email,
  &__phone {
    &[data-is-showing-errors] {
      input {
        border: 2px solid #ff6691;
      }
    }
    .formulate-input-errors {
      font-family: "GTAmericaEx";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #ff6691;
    }
    input {
      width: 100%;
      height: 65px;
      background: rgba(62, 198, 148, 0.15);
      border-radius: 6px;
      border: none;
      padding-left: 17px;
      padding-right: 17px;
      outline: none;
      box-sizing: border-box;

      font-family: "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #fff;

      &::placeholder {
        color: #38ce97;
      }

      &:focus,
      &:hover {
        background: inherit;
        border: 2px solid #38ce97;
      }

      @media (max-width: 900px) {
        height: 48px;
      }
    }

    &--driver,
    &--park {
      input {
        background: rgba(0, 80, 81, 0.1);

        &::placeholder {
          color: #fff;
        }

        &:focus,
        &:hover {
          border: 2px solid #005051;
        }
      }
    }

    &-information--park {
      input {
        background: rgba(165, 98, 255, 0.1);

        &::placeholder {
          color: #a562ff;
        }

        &:focus,
        &:hover {
          border: 2px solid #a562ff;
        }
      }
    }
  }

  &__checkbox {
    &[data-is-showing-errors] {
      input ~ label {
        border: 2px solid #ff6691;
      }
    }
    .formulate-input-errors {
      font-family: "GTAmericaEx";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #ff6691;
    }
    .formulate-input-wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-left: 3px;

      .formulate-input-label {
        font-family: "Roboto Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        color: rgba(255, 255, 255, 0.5);

        @media (max-width: 900px) {
          text-align: left;
          margin-top: 15px;
        }

        a {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input ~ label {
      background: rgba(62, 198, 148, 0.15);
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 3px;
      margin-right: 12px;
    }

    input:checked ~ label {
      background: rgba(62, 198, 148, 0.15);
      width: 18px;
      height: 18px;
      border: 1px solid #38ce97;
      box-sizing: border-box;

      &::before {
        content: "\E800";
        font-family: "fontello";
        position: relative;
        left: 0;
        color: #38ce97;
      }
    }

    &--driver,
    &--park {
      input ~ label {
        background: rgba(0, 80, 81, 0.1);
      }

      input:checked ~ label {
        background: rgba(0, 80, 81, 0.1);
        border: 1px solid #005051;

        &::before {
          color: #fff;
        }
      }
    }

    &-information--park {
      input ~ label {
        background: rgba(165, 98, 255, 0.1);
      }

      input:checked ~ label {
        background: rgba(165, 98, 255, 0.1);
        border: 1px solid #a562ff;

        &::before {
          color: #a562ff;
        }
      }
    }

    @media (max-width: 900px) {
      .formulate-input-wrapper {
        margin-top: 4px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.dialog {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  left: 0;
  top: 0;
  z-index: 500;
  align-items: center;

  &__window {
    display: flex;

    width: 767px;
    height: 169px;
    background: #110033;
    border-radius: 12px;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    justify-content: space-between;
  }

  &__wrapper {
    padding-left: 33px;
  }

  &__thx {
    font-family: "GTAMericaEx";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 64px;
    text-align: left;

    color: #a562ff;
  }

  &__text {
    font-family: "GTAmericaEx";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-align: left;

    color: #ffffff;
  }

  &__img {
    position: relative;
    top: 11px;
    left: -28px;
  }

  @media (max-width: 600px) {
    &__window {
      width: calc(100% - 16px);
      height: 108px;
    }

    &__wrapper {
      height: 100%;
      padding-top: 33px;
      padding-left: 17px;
    }

    &__thx {
      font-family: "Roboto";
      font-size: 24px;
      line-height: 32px;
    }

    &__text {
      margin-top: 8px;
      font-size: 10px;
      line-height: 16px;
    }

    &__img {
      width: 110px;
      left: 0;
      top: 16px;
    }
  }
}

.taxi-form {
  min-height: 313px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 47px 87px 27px 33px;
  background: #005051;
  border-radius: 28px;
  box-sizing: border-box;

  &--driver {
    background: #38ce97;

    .taxi-form__btn {
      i {
        background: #005051;
      }
    }
  }

  &--park {
    background: #a562ff;

    .taxi-form__btn {
      i {
        background: #40256b;
      }

      &:hover {
        i {
          background: #e2dffa;
        }
      }
    }
  }

  &__form,
  &__wrapper {
    min-width: 300px;
  }

  &__form {
    display: flex;
    margin-left: 16px;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__wrapper {
    width: 647px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__title {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: left;
    /* letter-spacing: 6px; */

    color: #ffffff;
  }

  &__text {
    max-width: 88%;
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: left;

    /* letter-spacing: 5px; */

    color: #ffffff;
  }

  &__btn {
    padding: 0;
    display: flex;
    align-items: center;
    height: 32px;
    position: relative;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    letter-spacing: 0.4px;

    color: #ffffff;

    border: none;
    background: none;

    cursor: pointer;

    margin-top: 10px;

    &:hover {
      i {
        background: #c5f9e5;
      }

      rect {
        fill: #000;
      }
    }

    i {
      margin: 0;
      font-size: 32px;
      border-radius: 50%;
      margin-right: 16px;
      background: #38ce97;
      color: #fff;

      &::before {
        margin: 0;
      }
    }
  }
  @media (max-width: 900px) {
    padding: 17px;
    border-radius: 16px;
    .taxi-form {
      &__wrapper {
        width: 100%;
        min-width: 100%;
      }

      &__form {
        padding: 0;
        margin-left: 0;
        min-width: 100%;
        margin-top: 47px;
        margin-bottom: 0;
      }

      &__title {
        font-family: "GTAmericaEx";
        font-weight: 500;
        font-size: 29px;
        line-height: 38px;
        /* letter-spacing: 4px; */
      }

      &__text {
        font-family: "GTAmericaEx";
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        /* letter-spacing: 2px; */
        margin-top: 10px;

        max-width: 85%;
      }

      &__btn {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-top: 24px;
        padding-top: 7px;

        i {
          margin-right: 9px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media (min-width: 901px) and (max-width: 1440px) {
    padding: 47px 102px 27px 33px;
    .taxi-form {
      &__wrapper {
        width: 569px;
      }

      &__text {
        max-width: 100%;
      }
    }
  }
}
.information {
  min-height: 461px;

  &--park {
    background: #40256b;

    .taxi-form__btn {
      i {
        background: #a562ff;
      }
      &:hover {
        i {
          background: #e2dffa;
        }
      }
    }
  }

  &--driver,
  &--park {
    .taxi-form__title {
      max-width: 80%;
    }
  }

  @media (max-width: 900px) {
    &--driver,
    &--park {
      .taxi-form__title {
        max-width: 100%;
      }

      .taxi-form__text {
        max-width: 100%;
      }
    }

    .taxi-form__form {
      margin-top: 45px;
    }
  }
}
</style>
